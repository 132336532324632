import React from "react";
import {LogTypeFilters} from "../../store/oplog/actions/OpLogActionTypes";
import {useOpLogFormContainer} from "./Hooks/useOpLogFormContainer";
import {WithServiceState} from "store-fetch-wrappers";
import EditOperationalLogForm from "./Components/EditOperationalLogForm";
import FormHeader from "../Form/FormHeader";

const ServiceWrapper = WithServiceState(EditOperationalLogForm);

function OpLogFormContainer({logType}: Props) {
    const {opLogStore} = useOpLogFormContainer(logType);
    return (
        <React.Fragment>
            <div className="pt-2">
                <FormHeader headerName={`New ${logType} Log Entry`} />
                <ServiceWrapper
                    {...opLogStore}
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={false}
                />
            </div>
        </React.Fragment>
    );
}

export default OpLogFormContainer;

interface Props {
    logType: LogTypeFilters;
}
