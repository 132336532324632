import React from "react";
import MobileRowItem from "./MobileRowItem";
import {ExpandTableRow} from "../../../../../modules/Table/Store/Actions/ExpandingTableActionTypes";
import {ExpandedRow} from "../../../../../modules/Table/Helpers/TableHelpers";
import {OpLog} from "../../../../../api/oplogs";
import {useExpandingTableRow} from "../../../../../modules/Table/Hooks/useExpandingTableRow";

function MobileRowItemContainer({log, expandedRow, initialExpandedRows}: Props<OpLog>) {
    const rowId = `row-expand-${log.id}`;
    const {renderExpandableRow, onExpandedItemSelected, expanded} = useExpandingTableRow({
        expandedRow,
        initialExpandedRows,
        row: log,
        rowId
    });

    return (
        <React.Fragment>
            <MobileRowItem
                opLog={log}
                onRowClicked={onExpandedItemSelected}
                className={"mobile-view-wrapper"}
            />
            {expanded && (
                <React.Fragment>
                    {
                        //@ts-ignore
                        renderExpandableRow
                    }
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default MobileRowItemContainer;

interface Props<T> {
    log: T;
    expandedRow: ExpandedRow<T>;
    initialExpandedRows?: ExpandTableRow[];
}
