import {useDispatch} from "react-redux";
import {OpLog, OpLogSupplemental} from "../../../api/oplogs";
import {createBlankOpLog, saveOpLog, updateOpLog} from "../../../store/oplog/actions/OpLogActions";
import {showErrorToast, showSuccessToast} from "../../../utils/toastUtils";
import {LogType} from "../../../store/oplog/actions/OpLogActionTypes";
import {useBaseFormValidation} from "./useBaseFormValidation";
import {getLogTypeFromString} from "../../../utils/enumUtils";
import {useOpLogBaseFilters} from "../../Pages/OperationalLogsList/Hooks/Shared/useOpLogBaseFilters";
import {getOpLogsPaged} from "../../../store/oplogList/actions/OpLogListActions";

export function useEditOperationalLogsForm() {
    const dispatch = useDispatch();
    const {validateStrategicLog, validateTacticalLog} = useBaseFormValidation();
    const {getOpLogsListRequestFromQuery} = useOpLogBaseFilters();

    function updateOperationLogs(opLog: OpLog | OpLogSupplemental): void {
        dispatch(updateOpLog(opLog));
    }

    function clearForm(opLog: OpLog, showMessage: boolean) {
        const logType = getLogTypeFromString(opLog.type);
        dispatch(createBlankOpLog(logType));

        if (!showMessage) return;
        showSuccessToast(`Cleared ${logType} Log form.`);
    }

    async function save(log: OpLog) {
        const isValid = validateForm(log);
        if (!isValid) return;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(saveOpLog(log));
        if (!success) return;
        showSuccessToast(`Saved ${log.type} Log`);
        clearForm(log, false);

        refreshOpLogListing(log);
    }

    function refreshOpLogListing(log: OpLog) {
        const defaultLogType = getLogTypeFromString(log.type);
        const initialRequest = getOpLogsListRequestFromQuery(defaultLogType);

        dispatch(getOpLogsPaged(initialRequest));
    }

    function validateForm(log: OpLog): boolean {
        const {remarks} = log;

        if (remarks.length > 10000) {
            showErrorToast("Remarks field must be less than or equal to 10000 characters.");
            return false;
        }
        switch (log.type) {
            case LogType.Tactical:
                return validateTacticalLog(log);
            case LogType.Strategic:
                return validateStrategicLog(log);

            default:
                return false;
        }
    }

    return {
        updateOperationLogs,
        save,
        clearForm,

        //Testing purposes
        validateForm,
        validateTacticalLog,
        validateStrategicLog
    };
}
