import React from "react";
import {OpLogListRequest} from "../../../../../api/oplogs";
import PulseDatePicker from "../../../../DatePicker/PulseDatePicker";
import moment from "moment";
import {
    AllowedAdditionalFields,
    SapType
} from "../../../../../store/oplog/actions/OpLogActionTypes";
import PulseEnumDropdown from "../../../../Dropdown/PulseEnumDropdown";
import {DebounceInput} from "react-debounce-input";

function StrategicLogsFilters({request, updateRequest}: LogFiltersProps) {
    return (
        <React.Fragment>
            <div className="filter-inner-container-wrapper">
                <div className="filter-item">
                    <h6>Date</h6>
                    {request && (
                        <PulseDatePicker
                            onChange={(date) => {
                                if (!request) return;
                                if (!date) return;
                                const momentStamp = moment(date);
                                const minDate = momentStamp.clone().startOf("month").unix();
                                const maxDate = momentStamp.clone().endOf("month").unix();

                                updateRequest({
                                    ...request,
                                    minDate,
                                    maxDate
                                });
                            }}
                            initialDate={request?.minDate}
                            showMonthYearPicker
                            dateFormat="MMMM yyyy"
                        />
                    )}
                </div>
                <div className="filter-item">
                    <h6>SAP Type</h6>
                    <PulseEnumDropdown
                        searchable={false}
                        enumOptions={SapType}
                        disabled={false}
                        placeholder={"Select SAP Type..."}
                        value={
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            request?.additionalFields[AllowedAdditionalFields.sapType]
                        }
                        clearable={true}
                        onChange={(option) => {
                            if (!request) return;
                            updateRequest({
                                ...request,
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                additionalFields: {
                                    ...request?.additionalFields,
                                    [AllowedAdditionalFields.sapType]: option?.value.toString()
                                }
                            });
                        }}
                        appendToBody
                    />
                </div>
                <div className="filter-item">
                    <h6>Strategic Intention</h6>
                    <DebounceInput
                        debounceTimeout={300}
                        className={"input-fields-filters"}
                        onChange={(event) => {
                            const {value} = event.target;
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            updateRequest({
                                ...request,
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                additionalFields: {
                                    ...request?.additionalFields,
                                    [AllowedAdditionalFields.strategicIntention]: value
                                }
                            });
                        }}
                        disabled={false}
                        value={
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            request?.additionalFields[AllowedAdditionalFields.strategicIntention]
                        }
                        placeholder="Enter Strategic Intention here..."
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

export default StrategicLogsFilters;

interface LogFiltersProps {
    request: OpLogListRequest | undefined;
    updateRequest: (request: OpLogListRequest) => void;
}
