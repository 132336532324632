import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {OpLogComplete} from "../../../api/oplogs";
import {OPLOG_STORE_STATE} from "../actions/OpLogActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<OpLogComplete>(null);

const opLogReducer = (
    state: StoreServiceData<OpLogComplete> = defaultState,
    action: ServiceActionTypes<OpLogComplete>
) => createReducer(state, action, OPLOG_STORE_STATE, () => showErrorToast(action.error));

export default opLogReducer;
