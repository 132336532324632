import {CalendarApi} from "../../api/grs";

export default class GrsApiModel {
    private static instance: GrsApiModel;
    private static calendarApi: CalendarApi;

    public static getInstance(): GrsApiModel {
        if (!GrsApiModel.instance) {
            GrsApiModel.instance = new GrsApiModel();
        }
        return GrsApiModel.instance;
    }

    public static getCalendarApi(): CalendarApi {
        if (!GrsApiModel.calendarApi) {
            GrsApiModel.calendarApi = new CalendarApi({
                basePath: process.env.REACT_APP_GRS_API
            });
        }

        return GrsApiModel.calendarApi;
    }
}
