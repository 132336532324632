import {useOperationLogsList} from "../Hooks/Shared/useOperationLogsList";
import React from "react";
import {LogTypeFilters} from "../../../../store/oplog/actions/OpLogActionTypes";
import {TabList, Tabs} from "react-tabs";

const StrategicLogList = () => {
    const {onTabSelect, tabs, selectedIndex, tabPanels} = useOperationLogsList(
        LogTypeFilters.Strategic
    );

    return (
        <React.Fragment>
            <div className="page-container pt-4">
                {tabs && tabPanels && (
                    <Tabs
                        selectedTabClassName="mc-tab--selected"
                        selectedIndex={selectedIndex}
                        onSelect={onTabSelect}
                    >
                        <TabList className="mc-tab-list">{tabs}</TabList>
                        {tabPanels}
                    </Tabs>
                )}
            </div>
        </React.Fragment>
    );
};

export default StrategicLogList;
