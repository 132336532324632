import React from "react";
import {OpLogListRequest} from "../../../../../api/oplogs";
import PulseDatePicker from "../../../../DatePicker/PulseDatePicker";
import moment from "moment";
import {DebounceInput} from "react-debounce-input";
import {AllowedAdditionalFields} from "../../../../../store/oplog/actions/OpLogActionTypes";
import CalendarListDropdown from "../../../../Dropdown/CalendarListDropdown";

function TacticalLogsFilters({request, updateRequest}: LogFiltersProps) {
    return (
        <React.Fragment>
            <div className="filter-inner-container-wrapper">
                <div className="filter-item">
                    <h6>Date</h6>
                    {request && (
                        <PulseDatePicker
                            onChange={(date) => {
                                if (!request) return;
                                if (!date) return;
                                const momentStamp = moment(date);
                                const minDate = momentStamp.clone().startOf("month").unix();
                                const maxDate = momentStamp.clone().endOf("month").unix();

                                updateRequest({
                                    ...request,
                                    minDate,
                                    maxDate
                                });
                            }}
                            initialDate={request?.minDate}
                            showMonthYearPicker
                            dateFormat="MMMM yyyy"
                        />
                    )}
                </div>
                <div className="filter-item">
                    <h6>Call Sign</h6>
                    <DebounceInput
                        debounceTimeout={300}
                        className={"input-fields-filters"}
                        onChange={(event) => {
                            const {value} = event.target;
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            updateRequest({
                                ...request,
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                additionalFields: {
                                    ...request?.additionalFields,
                                    [AllowedAdditionalFields.callSign]: value
                                }
                            });
                        }}
                        disabled={false}
                        value={
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            request?.additionalFields[AllowedAdditionalFields.callSign]
                        }
                        placeholder="Enter Callsign here..."
                    />
                </div>
                <div className="filter-item">
                    <h6>Department</h6>
                    <CalendarListDropdown
                        onChange={(calendar) => {
                            updateRequest({
                                ...request,
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                additionalFields: {
                                    ...request?.additionalFields,
                                    [AllowedAdditionalFields.departmentId]: calendar?.id.toString()
                                }
                            });
                        }}
                        calendarId={
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            +request?.additionalFields[AllowedAdditionalFields.departmentId]
                        }
                        clearable={true}
                        searchable={true}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}

export default TacticalLogsFilters;

interface LogFiltersProps {
    request: OpLogListRequest | undefined;
    updateRequest: (request: OpLogListRequest) => void;
}
