import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {CalendarSummary} from "../../../api/grs";
import {CALENDAR_LIST_STORE} from "../actions/CalendarListActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<CalendarSummary[]>([]);

const calendarListReducer = (
    state: StoreServiceData<CalendarSummary[]> = defaultState,
    action: ServiceActionTypes<CalendarSummary[]>
) => createReducer(state, action, CALENDAR_LIST_STORE, () => showErrorToast(action.error));

export default calendarListReducer;
