import {Dispatch} from "redux";
import {CALENDAR_LIST_STORE, CalendarListDispatchTypes} from "./CalendarListActionTypes";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import GrsApiModel from "../../apiModel/GrsApiModel";

export function nullifyCalendarListStore() {
    return async function (dispatch: Dispatch<CalendarListDispatchTypes>) {
        dispatch({
            type: CALENDAR_LIST_STORE.SUCCESS,
            error: null,
            loading: false,
            data: []
        });
    };
}

export function getCalendarList() {
    return async function (dispatch: Dispatch<CalendarListDispatchTypes>) {
        try {
            return await getDataFromServiceWithRedux(
                CALENDAR_LIST_STORE,
                dispatch,
                () => GrsApiModel.getCalendarApi().listCalendarSummary(),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: CALENDAR_LIST_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
}
