//Getting enum as a generic. Accepts string/numeric/heterogeneous

import {LogType, LogTypeFilters} from "../store/oplog/actions/OpLogActionTypes";

export type Enum<E> = Record<keyof E, number | string> & {[k: number]: string};

export function getLogTypeFromString(value?: string | null): LogType {
    if (!value) return LogType.None;

    return LogType[value as keyof typeof LogType];
}

export function getLogTypeFiltersFromString(value: string | null): LogTypeFilters {
    return LogTypeFilters[value as keyof typeof LogTypeFilters];
}
