import React, {useState} from "react";
import {OpLog, OpLogSupplemental} from "../../../../../api/oplogs";
import {formatUnixToCustomFormat} from "../../../../../utils/momentUtils";
import {getLogTypeFiltersFromString} from "../../../../../utils/enumUtils";
import {
    AllowedAdditionalFields,
    LogTypeFilters
} from "../../../../../store/oplog/actions/OpLogActionTypes";

export function useMobileRowItem(log: OpLog | OpLogSupplemental) {
    const [collapseClass, setCollapseClass] = useState<string>("closed");
    const [collapsed, setCollapsed] = useState<boolean>(true);

    function getAuthorInitials(): string {
        const {staffName} = log.author;
        const [firstName, lastName] = staffName.split(" ");

        return `${firstName.at(0)}${lastName.at(0)}`;
    }
    function getFormattedTime() {
        return formatUnixToCustomFormat(log.dateSaved, "DD/MM/YY HH:mm");
    }

    function getDetailsMarkup(header: string, value: string): React.ReactNode {
        return (
            <div className="mobile-view-details">
                <h6 className="mb-0">{header}</h6>
                <p className="mb-0">{value}</p>
            </div>
        );
    }

    function getBasicDetailsMarkup() {
        return (
            <React.Fragment>
                {getDetailsMarkup("Date Created", getFormattedTime())}
                {getDetailsMarkup("Remarks", log.remarks)}
                {isSupplementalLog(log) && (
                    <React.Fragment>
                        {getDetailsMarkup("Author", log.author.staffName)}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }

    function getAdditionalDetailsForLog() {
        if (!("type" in log)) return;
        if (!log.type) return;
        const logType = getLogTypeFiltersFromString(log.type);

        switch (logType) {
            case LogTypeFilters.Strategic:
                return getStrategicAdditionalInfo();
            case LogTypeFilters.Tactical:
                return getTacticalAdditionalInfo();
        }
    }

    function isSupplementalLog(opLog: OpLog | OpLogSupplemental) {
        return "parentId" in opLog;
    }

    function getTacticalAdditionalInfo(): React.ReactNode {
        const {callSign, departmentName} = AllowedAdditionalFields;

        return (
            <React.Fragment>
                {getDetailFromAdditionalDetails("Department", departmentName)}
                {getDetailFromAdditionalDetails("Call Sign", callSign)}
                {getDetailsMarkup("Author", log.author.staffName)}
            </React.Fragment>
        );
    }

    function getStrategicAdditionalInfo(): React.ReactNode {
        const {sapType, strategicIntention} = AllowedAdditionalFields;

        return (
            <React.Fragment>
                {getDetailFromAdditionalDetails("SAP Type", sapType)}
                {getDetailFromAdditionalDetails("Strategic Intention", strategicIntention)}
                {getDetailsMarkup("Author", log.author.staffName)}
            </React.Fragment>
        );
    }

    function getDetailFromAdditionalDetails(title: string, key: string): React.ReactNode {
        if (!log.additionalFields) return getDetailsMarkup(title, "N/A");

        const item = log.additionalFields[key];

        if (!item) return getDetailsMarkup(title, "N/A");

        return getDetailsMarkup(title, item);
    }

    function toggleExtendedDetails(value: boolean) {
        setCollapsed((value = !value));

        if (value) {
            setCollapseClass("closed");
            return;
        }
        setCollapseClass("open");
    }

    return {
        getAuthorInitials,
        getFormattedTime,
        getBasicDetailsMarkup,
        collapseClass,
        collapsed,
        toggleExtendedDetails,
        getAdditionalDetailsForLog,
        isSupplementalLog
    };
}
