import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {CalendarSummary} from "../../../api/grs";

export const CALENDAR_LIST_STORE = createStoreState("calendar_list");

interface CalendarListLoading extends StoreServiceData<CalendarSummary[]> {
    type: typeof CALENDAR_LIST_STORE.LOADING;
}
interface CalendarListError extends StoreServiceData<CalendarSummary[]> {
    type: typeof CALENDAR_LIST_STORE.LOADING;
}
interface CalendarListSuccess extends StoreServiceData<CalendarSummary[]> {
    type: typeof CALENDAR_LIST_STORE.LOADING;
}

export type CalendarListDispatchTypes =
    | CalendarListLoading
    | CalendarListError
    | CalendarListSuccess;
