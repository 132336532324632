import React from "react";
import SupplementalLogsTable from "./Components/SupplementalLogsTable";
import {WithServiceState} from "store-fetch-wrappers";
import {useSupplementalLogListContainer} from "../Shared/Hooks/useSupplementalLogListContainer";
import HeaderWithActions from "./Components/HeaderWithActions";
import {IconType} from "../../../Icon/Icon";
import EditSupplementalLogDesktopForm from "../../Form/Components/Desktop/EditSupplementalLogDesktopForm";

const ServiceWrapper = WithServiceState(SupplementalLogsTable);

function SupplementalLogListDesktopContainer({id}: Props) {
    const {fetchData, state, setSupplementalLogFormOpened, supplementalLogFormOpened} =
        useSupplementalLogListContainer(id);

    return (
        <React.Fragment>
            <tr>
                <td colSpan={6}>
                    <HeaderWithActions
                        headerName={"Supplemental Logs"}
                        actions={[
                            {
                                icon: IconType.Refresh_Round,
                                onClick: () => fetchData(),
                                tooltipText: "Refresh Supplemental Logs"
                            },
                            {
                                icon: IconType.Add_Basic,
                                onClick: () => {
                                    let opened = supplementalLogFormOpened;
                                    setSupplementalLogFormOpened((opened = !opened));
                                },
                                tooltipText: "Add New Log"
                            }
                        ]}
                    />
                </td>
            </tr>
            {supplementalLogFormOpened && (
                <tr>
                    <td colSpan={6}>
                        <EditSupplementalLogDesktopForm
                            parentId={id}
                            onSave={async () => {
                                setSupplementalLogFormOpened(false);
                                await fetchData();
                            }}
                            onCancel={() => setSupplementalLogFormOpened(false)}
                        />
                    </td>
                </tr>
            )}
            <ServiceWrapper
                {...state}
                showLoadingText={false}
                loaderType={"overlay"}
                loaderWheelType={"three-ring"}
            />
            <tr>
                <td height={30} />
            </tr>
            <tr>
                <td colSpan={6} height={10} className="expanded-under" />
            </tr>
        </React.Fragment>
    );
}

export default SupplementalLogListDesktopContainer;

interface Props {
    id: number;
}
