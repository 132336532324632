import {useQuery} from "../../../../Hooks/useQuery";
import moment from "moment";
import {QueryStrings} from "./useOpLogsTableFilters";
import {OpLogListRequest} from "../../../../../api/oplogs";
import {
    AllowedAdditionalFields,
    DynamicKeyValue,
    LogType,
    LogTypeFilters
} from "../../../../../store/oplog/actions/OpLogActionTypes";
import {toQueryString} from "../../../../../utils/queryUtils";
import {useHistory} from "react-router-dom";

export function useOpLogBaseFilters() {
    const query = useQuery();
    const history = useHistory();

    function getOpLogsListRequestFromQuery(
        defaultLogType: LogTypeFilters | LogType
    ): OpLogListRequest {
        const departmentId = query.get(QueryStrings.departmentId);
        const sapType = query.get(QueryStrings.sapType);
        const callSign = query.get(QueryStrings.callSign);
        const author = query.get(QueryStrings.author);
        const pageNum = query.get(QueryStrings.pageNum);
        const minDate = query.get(QueryStrings.minDate);
        const maxDate = query.get(QueryStrings.maxDate);
        const strategicIntention = query.get(QueryStrings.strategicIntention);

        const now = moment();

        return {
            pageNum: pageNum ? +pageNum : 0,
            authorUsername: author || undefined,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            additionalFields: {
                [AllowedAdditionalFields.departmentId]: departmentId || undefined,
                [AllowedAdditionalFields.sapType]: sapType || undefined,
                [AllowedAdditionalFields.callSign]: callSign || undefined,
                [AllowedAdditionalFields.strategicIntention]: strategicIntention || undefined
            },
            type: defaultLogType || undefined,
            minDate: minDate ? +minDate : now.clone().startOf("month").unix(),
            maxDate: maxDate ? +maxDate : now.clone().endOf("month").unix()
        };
    }

    function updateSearchStrings(request: OpLogListRequest) {
        const search = buildUrlHistory(request);

        history.push({
            search: `?${search}`
        });
    }

    function buildUrlHistory({
        type,
        additionalFields,
        maxDate,
        minDate,
        pageNum,
        authorUsername
    }: OpLogListRequest) {
        const searchStrings: string[] = [];
        const departmentId = getAdditionFieldValue(
            AllowedAdditionalFields.departmentId,
            additionalFields
        );

        const callSign = getAdditionFieldValue(AllowedAdditionalFields.callSign, additionalFields);
        const sapType = getAdditionFieldValue(AllowedAdditionalFields.sapType, additionalFields);
        const strategicIntention = getAdditionFieldValue(
            AllowedAdditionalFields.strategicIntention,
            additionalFields
        );

        if (departmentId) {
            searchStrings.push(`departmentId=${departmentId}`);
        }

        if (sapType) {
            searchStrings.push(`sapType=${sapType}`);
        }

        if (callSign) {
            searchStrings.push(`callSign=${callSign}`);
        }

        if (strategicIntention) {
            searchStrings.push(`strategicIntention=${strategicIntention}`);
        }

        if (type) {
            searchStrings.push(`type=${type}`);
        }
        if (authorUsername) {
            searchStrings.push(`author=${authorUsername}`);
        }
        // @ts-ignore
        if (pageNum > -1) {
            searchStrings.push(`pageNum=${pageNum}`);
        }
        searchStrings.push(`minDate=${minDate}`);
        searchStrings.push(`maxDate=${maxDate}`);

        return toQueryString(searchStrings);
    }

    function getAdditionFieldValue(
        key: string,
        additionalFields?: DynamicKeyValue | undefined
    ): string | undefined {
        return additionalFields ? additionalFields[key] : undefined;
    }

    return {
        getOpLogsListRequestFromQuery,
        buildUrlHistory,
        updateSearchStrings
    };
}
