import {DefaultApi as OpLogsApi} from "../../api/oplogs";

export default class OpLogsApiModel {
    private static instance: OpLogsApiModel;
    private static opLogsApi: OpLogsApi;

    public static getInstance(): OpLogsApiModel {
        if (!OpLogsApiModel.instance) {
            OpLogsApiModel.instance = new OpLogsApiModel();
        }
        return OpLogsApiModel.instance;
    }

    public static getOpLogsApi(): OpLogsApi {
        if (!OpLogsApiModel.opLogsApi) {
            OpLogsApiModel.opLogsApi = new OpLogsApi({
                basePath: process.env.REACT_APP_OPLOGS_API,
                isJsonMime(mime: string): boolean {
                    return false;
                }
            });
        }

        return OpLogsApiModel.opLogsApi;
    }
}
