import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../../store/Store";
import {getCalendarList} from "../../../../../store/calendarList/actions/CalendarListActions";
import {LogTypeFilters} from "../../../../../store/oplog/actions/OpLogActionTypes";
import {getLogTypeFiltersFromString} from "../../../../../utils/enumUtils";
import {Tab, TabPanel} from "react-tabs";
import {WithServiceState} from "store-fetch-wrappers";
import {useQuery} from "../../../../Hooks/useQuery";
import {QueryStrings} from "./useOpLogsTableFilters";
import {toQueryString} from "../../../../../utils/queryUtils";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../../Navigation/routeNames";
import OpLogsTableWrapper from "../../Components/Shared/OpLogsTableWrapper";

const ServiceWrapper = WithServiceState(OpLogsTableWrapper);

export function useOperationLogsList(logType: LogTypeFilters) {
    const dispatch = useDispatch();
    const operationLogsListStore = useSelector((state: RootStore) => state.opLogList);
    const [selectedIndex, setSelectedIndex] = useState<number>(-1);
    const [tabs, setTabs] = useState<React.ReactNode>();
    const query = useQuery();
    const history = useHistory();

    useEffect(() => {
        setTabs(getTabs());
        dispatch(getCalendarList());
    }, []);

    function onTabSelect(index: number, last: number, event: Event) {
        const {target} = event;
        if (!target) return;
        // @ts-ignore
        const targetLogType = getLogTypeFiltersFromString(target.innerHTML);

        // We don't want to select the log we are already on and force a refresh
        if (logType === targetLogType) return;
        goToSelectedTab(targetLogType);
    }

    function goToSelectedTab(targetTab: LogTypeFilters): void {
        const queryStrings = getQueryStrings(targetTab);
        const pathname = `${routeNames.logListParent.path}/${targetTab.toLowerCase()}`;
        history.push({pathname, search: queryStrings});
    }

    function getQueryStrings(targetLogType: LogTypeFilters) {
        const searchStrings: string[] = [];
        const minDate = query.get(QueryStrings.minDate);
        const maxDate = query.get(QueryStrings.maxDate);

        searchStrings.push(`type=${targetLogType}`);

        if (maxDate) {
            searchStrings.push(`minDate=${minDate}`);
        }
        if (maxDate) {
            searchStrings.push(`maxDate=${maxDate}`);
        }

        return toQueryString(searchStrings, true);
    }

    function getTabs() {
        return (
            <React.Fragment>
                {Object.keys(LogTypeFilters).map((key, index) => {
                    const type = getLogTypeFiltersFromString(key);
                    if (type === logType) {
                        setSelectedIndex(index);
                    }
                    return (
                        <Tab className="mc-tab" key={key}>
                            {key}
                        </Tab>
                    );
                })}
            </React.Fragment>
        );
    }

    //Gets tab panels with the correct log type injected into it
    function getTabPanels() {
        return (
            <React.Fragment>
                {Object.keys(LogTypeFilters).map((key, index) => {
                    const type = getLogTypeFiltersFromString(key);
                    if (type === logType) {
                        return (
                            <TabPanel key={`${key} - ${index}`}>
                                <ServiceWrapper
                                    {...operationLogsListStore}
                                    loaderWheelType={"three-ring"}
                                    loaderType={"overlay"}
                                    showLoadingText={false}
                                    data={{
                                        logType: type,
                                        response: operationLogsListStore.data
                                    }}
                                />
                            </TabPanel>
                        );
                    }
                    return <TabPanel key={`${key} - ${index}`} />;
                })}
            </React.Fragment>
        );
    }

    return {
        operationLogsListStore,
        onTabSelect,
        tabs,
        selectedIndex,
        tabPanels: getTabPanels()
    };
}
