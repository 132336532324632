import React from "react";

const FormHeader = ({headerName, className, sticky}: FormHeaderProps) => {
    function getStickyStyle(): React.CSSProperties | undefined {
        if (!sticky) return;

        return {
            position: "sticky",
            bottom: "-1px"
        };
    }

    return (
        <div className="row ml-0 mr-0 mc-form-heading" style={getStickyStyle()}>
            <div className="col">
                <h5 className={`mb-0 ${className || ""}`}>{headerName}</h5>
            </div>
        </div>
    );
};

export default FormHeader;

interface FormHeaderProps {
    headerName: string;
    className?: string;
    sticky?: boolean;
}
