import {useEffect} from "react";
import {LogTypeFilters} from "../../../store/oplog/actions/OpLogActionTypes";
import {createBlankOpLog} from "../../../store/oplog/actions/OpLogActions";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";

export function useOpLogFormContainer(logType: LogTypeFilters) {
    const dispatch = useDispatch();
    const opLogStore = useSelector((state: RootStore) => state.opLog);
    useEffect(() => {
        dispatch(createBlankOpLog(logType));
    }, []);

    return {
        opLogStore
    };
}
