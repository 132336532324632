export const routeNames = {
    logListSelector: {
        path: "/logs/select",
        name: "Operational Logs"
    },
    logListParent: {
        path: "/logs",
        name: ""
    },
    tacticalLogList: {
        path: "/logs/tactical",
        name: "Tactical Logs"
    },
    strategicLogList: {
        path: "/logs/strategic",
        name: "Strategic Logs"
    },
    logsEdit: {
        path: "/logs/edit",
        name: "Edit Operational Log"
    },
    logsNew: {
        path: "/logs/new",
        name: "New Operational Log"
    },
    //Auth pages
    login: {
        path: "/login",
        name: "",
        icon: ""
    },
    loggedin: {
        path: "/loggedin",
        name: "",
        icon: ""
    },
    logout: {
        path: "/logout",
        name: "",
        icon: ""
    },
    inActive: {
        path: "/inactive",
        name: "",
        icon: ""
    },
    notAuthorised: {
        path: "/not-authorised",
        name: "",
        icon: ""
    },
    notFound: {
        path: "/not-found",
        name: "",
        icon: ""
    }
};
