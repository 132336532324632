import {useEffect, useState} from "react";
import {
    ExpandTableRow,
    TableRedirect
} from "../../../../../modules/Table/Store/Actions/ExpandingTableActionTypes";
import {LogTypeFilters} from "../../../../../store/oplog/actions/OpLogActionTypes";

export function useOpenLogRows(logType: LogTypeFilters) {
    const [openRows, setOpenRows] = useState<ExpandTableRow[]>([]);

    useEffect(() => {
        const redirectSetting = localStorage.getItem("oplog_redirect");
        if (!redirectSetting) {
            setOpenRows([]);
            return;
        }
        const {url, selectedRow}: TableRedirect = JSON.parse(redirectSetting);

        setOpenRows(url.includes(logType) ? selectedRow : []);
    }, []);

    return {
        openRows
    };
}
