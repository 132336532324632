import React from "react";
import {
    CustomRenderers,
    ExpandedRow,
    ExpandingTableData,
    objectKeys
} from "../Helpers/TableHelpers";
import ExpandingTableRowCell, {ExpandAction} from "./ExpandingTableRowCell";
import {useExpandingTableRow} from "../Hooks/useExpandingTableRow";
import {isEven} from "../../../utils/mathUtils";
import {ExpandTableRow} from "../Store/Actions/ExpandingTableActionTypes";

function ExpandingTableRow<T extends ExpandingTableData>({
    customRenderers,
    item,
    expandedRow,
    viewActionText,
    rowIndex,
    initialExpandedRows
}: Props<T>) {
    const rowId = `row-expand-${item.id}`;
    const {renderExpandableRow, onExpandedItemSelected, rowOpenType, getPrefixText, expanded} =
        useExpandingTableRow({
            expandedRow,
            initialExpandedRows,
            row: item,
            rowId
        });

    return (
        <React.Fragment>
            <tr
                className={` ${isEven(rowIndex) ? "expandable-even-row" : "expandable-odd-row"} ${
                    expanded ? "expanded" : ""
                }`}
                onClick={() => {
                    onExpandedItemSelected({item, action: ExpandAction.View});
                }}
                id={rowId}
            >
                {objectKeys(item).map((itemProperty, index) => {
                    const customRenderer = customRenderers?.[itemProperty];

                    return (
                        <ExpandingTableRowCell
                            customRenderer={customRenderer}
                            viewActionText={`${getPrefixText(rowOpenType, true)} ${viewActionText}`}
                            item={item}
                            itemKey={itemProperty.toString()}
                            key={index}
                            onExpandableItemClicked={onExpandedItemSelected}
                            expanded={expanded}
                        />
                    );
                })}
            </tr>
            {expanded && (
                <React.Fragment>
                    {
                        //@ts-ignore
                        renderExpandableRow
                    }
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

export default ExpandingTableRow;

interface Props<T extends ExpandingTableData> {
    customRenderers?: CustomRenderers<T>;
    item: T;
    expandedRow: ExpandedRow<T>;
    viewActionText: string;
    rowIndex: number;
    initialExpandedRows?: ExpandTableRow[];
}
