import {CalendarSummary} from "../../../api/grs";
import {BaseDropdownProps, DropdownOption} from "../Helpers/dropdownUtils";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import {SingleValue} from "react-select";

export function useCalendarListDropdown({calendarId, onChange}: PulseCalendarDropdown) {
    const [options, setOptions] = useState<DropdownOption[]>([]);
    const [selectedOption, setSelectedOption] = useState<DropdownOption | null>();
    const calendarListStore = useSelector((state: RootStore) => state.calendarList);

    useEffect(() => {
        const {data} = calendarListStore;
        if (!data) return;

        processCalendarListIncoming(data);
    }, [calendarListStore]);

    useEffect(() => {
        if (!calendarId) {
            setSelectedOption(null);
            return;
        }
        if (options.length === 0) return;

        const option = options.find((item) => +item.value === calendarId);

        setSelectedOption(option);
    }, [calendarId, options]);

    function processCalendarListIncoming(list: CalendarSummary[]): void {
        const generated = getDropdownOptionsFromCalendarList(list);
        setOptions(generated);
    }

    function getDropdownOptionsFromCalendarList(list: CalendarSummary[]): DropdownOption[] {
        return list.map((cal) => {
            return {
                value: cal.id,
                label: cal.name
            };
        });
    }

    function handleCalendarChange(newValue: SingleValue<DropdownOption>): void {
        setSelectedOption(newValue);
        const calendarOption = getCalendarSummaryFromDropdownOption(newValue);
        onChange(calendarOption);
    }

    function getCalendarSummaryFromDropdownOption(
        entity: SingleValue<DropdownOption>
    ): CalendarSummary | null | undefined {
        if (!entity) return;

        return calendarListStore.data?.find((calendar) => calendar.id === +entity.value);
    }

    return {
        options,
        selectedOption,
        handleCalendarChange,
        calendarListStore
    };
}

export interface PulseCalendarDropdown extends BaseDropdownProps<CalendarSummary> {
    calendarId?: number;
}
