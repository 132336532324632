import React, {useEffect, useState} from "react";
import {OpLog, OpLogListResponse} from "../../../../../api/oplogs";

import {
    StrategicLogTableRow,
    TacticalLogTableRow,
    useOpLogTableRow
} from "../../../../OpLogTableRow/Hooks/useOpLogTableRow";
import {LogTypeFilters} from "../../../../../store/oplog/actions/OpLogActionTypes";

export function useOpLogsDesktopTable({response, logType}: OpLogDesktopTableProps) {
    const {getTacticalLogTableRow, getStrategicLogTableRow} = useOpLogTableRow();
    const [items, setItems] = useState<TableRowTactical[] | StrategicLogTableRow[]>([]);

    useEffect(() => {
        setItems(getTableRows(response));
    }, [response]);

    function getTableRows({logs}: OpLogListResponse): TableRowTactical[] | StrategicLogTableRow[] {
        switch (logType) {
            case LogTypeFilters.Tactical:
                return getTableRowTactical(logs);
            case LogTypeFilters.Strategic:
                return getTableRowStrategic(logs);
        }
    }

    function getTableRowTactical(logs: OpLog[]): TableRowTactical[] {
        return logs.map((log) => {
            const row = getTacticalLogTableRow(log);
            return {
                ...row,
                actions: <div />
            };
        });
    }

    function getTableRowStrategic(logs: OpLog[]): TableRowStrategic[] {
        return logs.map((log) => {
            const row = getStrategicLogTableRow(log);
            return {
                ...row,
                actions: <div />
            };
        });
    }

    function getTableHeaders() {
        switch (logType) {
            case LogTypeFilters.Tactical:
                return getTacticalLogsHeaders();
            case LogTypeFilters.Strategic:
                return getStrategicLogsHeaders();
        }
    }

    function getTacticalLogsHeaders() {
        return {
            createdBy: "Created By",
            dateCreated: "Date Created",
            remarks: "Remarks",
            department: "Department",
            callSign: "Call Sign",
            actions: "Actions"
        };
    }

    function getStrategicLogsHeaders() {
        return {
            createdBy: "Created By",
            dateCreated: "Date Created",
            remarks: "Remarks",
            sapType: "SAP Type",
            strategicIntention: "Strategic Intention",
            actions: "Actions"
        };
    }

    return {
        items,
        headers: getTableHeaders()
    };
}

export interface TableRowTactical extends TacticalLogTableRow {
    actions: React.ReactNode;
}

export interface TableRowStrategic extends StrategicLogTableRow {
    actions: React.ReactNode;
}

export interface OpLogDesktopTableProps {
    logType: LogTypeFilters;
    response: OpLogListResponse;
}
