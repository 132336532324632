import React from "react";
import {useSupplementalLogsTable} from "../Hooks/useSupplementalLogsTable";
import {OpLogSupplemental} from "../../../../../api/oplogs";
import SupplementalLogsTableHeader from "./SupplementalLogsTableHeader";
import NoItemsMessage from "../../../../Table/NoItemsMessage";
import SupplementalLogsTableRow from "./SupplementalLogsTableRow";

function SupplementalLogsTable(props: OpLogSupplemental[]) {
    const {items, headers} = useSupplementalLogsTable(props);
    return (
        <React.Fragment>
            {items.length > 0 ? (
                <React.Fragment>
                    <SupplementalLogsTableHeader headers={headers} />
                    {items.map((item, key) => {
                        return (
                            <SupplementalLogsTableRow
                                remarks={item.remarks}
                                key={key}
                                author={item.createdBy}
                                dateCreated={item.dateCreated}
                            />
                        );
                    })}
                </React.Fragment>
            ) : (
                <tr>
                    <td colSpan={6}>
                        <NoItemsMessage message={"There are no supplemental logs"} />
                    </td>
                </tr>
            )}
        </React.Fragment>
    );
}

export default SupplementalLogsTable;
