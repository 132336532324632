import React from "react";
import {PlatformOS, usePlatform} from "../../../../Hooks/usePlatform";
import OpLogsDesktopTable, {OpLogTableProps} from "../../Components/Desktop/OpLogsDesktopTable";
import OpLogsMobileTable from "../../Components/Mobile/OpLogsMobileTable";

export function useOpLogsTableWrapper({logType, response}: OpLogTableProps) {
    const platform = usePlatform();

    function renderView() {
        switch (platform) {
            case PlatformOS.Desktop:
                return <OpLogsDesktopTable logType={logType} response={response} />;
            case PlatformOS.Mobile:
                return <OpLogsMobileTable logType={logType} response={response} />;
        }
    }

    return {
        renderView
    };
}
