import React from "react";
import Icon, {IconSize, IconType} from "../../../../Icon/Icon";
import Tooltip from "../../../../Tooltip/Tooltip";

function HeaderWithActions({headerName, actions = []}: Props) {
    function renderActions(): React.ReactNode {
        return actions?.map(({icon, hideIcon, tooltipText, onClick, iconSize}, key) => {
            if (hideIcon) return;

            if (tooltipText?.length) {
                return (
                    <Tooltip
                        key={key}
                        tooltipText={tooltipText}
                        wrapperClassName={"ml-2 cursor-pointer"}
                        size={"md"}
                        place={"right"}
                        theme={"light"}
                    >
                        <Icon rootElement={"Span"} icon={icon} size={"Medium"} onClick={onClick} />
                    </Tooltip>
                );
            }

            return (
                <Icon
                    key={key}
                    rootElement={"Span"}
                    icon={icon}
                    size={iconSize || "Medium"}
                    onClick={onClick}
                />
            );
        });
    }
    return (
        <React.Fragment>
            <h6 className="supplemental-table-header">
                {headerName}
                {renderActions()}
            </h6>
        </React.Fragment>
    );
}

export default HeaderWithActions;

interface Props {
    headerName: string;
    actions?: HeaderActions[];
}

interface HeaderActions {
    icon: IconType;
    onClick: (e: any) => void;
    tooltipText?: string;
    hideIcon?: boolean;
    iconSize?: IconSize;
}
