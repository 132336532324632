import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {OpLogComplete} from "../../../api/oplogs";

export const OPLOG_STORE_STATE = createStoreState("oplog");

interface OpLogLoading extends StoreServiceData<OpLogComplete> {
    type: typeof OPLOG_STORE_STATE.LOADING;
}
interface OpLogError extends StoreServiceData<OpLogComplete> {
    type: typeof OPLOG_STORE_STATE.ERROR;
}
interface OpLogSuccess extends StoreServiceData<OpLogComplete> {
    type: typeof OPLOG_STORE_STATE.SUCCESS;
}

export type OpLogDispatchTypes = OpLogLoading | OpLogError | OpLogSuccess;

// eslint-disable-next-line no-shadow
export enum LogType {
    Tactical = "Tactical",
    Strategic = "Strategic",
    None = "None"
}
// eslint-disable-next-line no-shadow
export enum LogTypeFilters {
    Tactical = "Tactical",
    Strategic = "Strategic"
}

// eslint-disable-next-line no-shadow
export enum SapType {
    Strategy = "Strategy",
    Action = "Action",
    PolicyDecision = "PolicyDecision"
}

export interface DynamicKeyValue {
    [key: string]: string;
}

export const AllowedAdditionalFields = {
    departmentId: "departmentId",
    departmentName: "departmentName",
    calendarType: "calendarType",
    callSign: "callSign",
    sapType: "sapType",
    strategicIntention: "strategicIntention"
};
