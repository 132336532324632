import {Dispatch} from "redux";
import {OPLOG_LIST_STORE_STATE, OpLogListDispatchTypes} from "./OpLogListActionTypes";
import {OpLogListRequest, OpLogListResponse} from "../../../api/oplogs";
import {postDataToServiceWithRedux} from "store-fetch-wrappers";
import OpLogsApiModel from "../../apiModel/OpLogsApiModel";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import {
    AllowedAdditionalFields,
    LogType,
    LogTypeFilters,
    SapType
} from "../../oplog/actions/OpLogActionTypes";
import {CalendarTypeEnum} from "../../../api/grs";
import {getBlankOpLogsListResponse} from "../reducer/OpLogsListReducer";

export function nullifyOpLogListStore() {
    return async function (dispatch: Dispatch<OpLogListDispatchTypes>) {
        dispatch({
            type: OPLOG_LIST_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: getBlankOpLogsListResponse()
        });
    };
}

export function getOpLogsPaged(request: OpLogListRequest) {
    return async function (dispatch: Dispatch<OpLogListDispatchTypes>) {
        const updatedRequest = getSanitisedRequest(request);

        try {
            return await postDataToServiceWithRedux(
                OPLOG_LIST_STORE_STATE,
                dispatch,
                () => OpLogsApiModel.getOpLogsApi().listLogs(updatedRequest),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: OPLOG_LIST_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
}

/** DEVELOPMENT ONLY */
export function getMockOpLogsList(logType: LogTypeFilters) {
    return async function (dispatch: Dispatch<OpLogListDispatchTypes>) {
        const listing = getMockOpLogListing();
        const filteredLogs = listing.logs.filter((item) => item.type === logType);
        dispatch({
            type: OPLOG_LIST_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: {
                ...listing,
                logs: filteredLogs
            }
        });
    };
}

//Check if any of the additional fields exist and if they're undefined. If they are, we want to remove them
function getSanitisedRequest(request: OpLogListRequest) {
    const {additionalFields} = request;
    if (!additionalFields) return request;
    const {callSign, sapType, departmentId, strategicIntention} = AllowedAdditionalFields;

    if (Object.hasOwn(additionalFields, callSign) && !additionalFields[callSign]) {
        delete additionalFields[callSign];
    }
    if (Object.hasOwn(additionalFields, sapType) && !additionalFields[sapType]) {
        delete additionalFields[sapType];
    }
    if (Object.hasOwn(additionalFields, departmentId) && !additionalFields[departmentId]) {
        delete additionalFields[departmentId];
    }
    if (
        Object.hasOwn(additionalFields, strategicIntention) &&
        !additionalFields[strategicIntention]
    ) {
        delete additionalFields[strategicIntention];
    }

    return {
        ...request,
        additionalFields: Object.keys(additionalFields).length > 0 ? additionalFields : undefined
    };
}

/** DEVELOPMENT ONLY */
export function getMockOpLogListing(): OpLogListResponse {
    return {
        additionalFields: {},
        authorUsername: "",
        logs: [
            {
                id: 1,
                type: LogType.Strategic,
                author: {
                    staffName: "Abs",
                    staffId: "abs_abs"
                },
                dateSaved: 1684540800,
                remarks: "Yes",
                additionalFields: {
                    sapType: SapType.Action
                }
            },
            {
                id: 2,
                type: LogType.Tactical,
                author: {
                    staffName: "Abs",
                    staffId: "abs_abs"
                },
                dateSaved: 1684540801,
                remarks: "Yes, No",
                additionalFields: {
                    departmentId: "1",
                    departmentName: "EOE Frontline Operations",
                    calendarType: CalendarTypeEnum.Frontline,
                    callSign: "PUR001"
                }
            },
            {
                id: 3,
                type: LogType.Tactical,
                author: {
                    staffName: "Abs",
                    staffId: "abs_abs"
                },
                dateSaved: 1684540801,
                remarks: "Yes, No",
                additionalFields: {
                    departmentId: "2",
                    departmentName: "Wembley",
                    calendarType: CalendarTypeEnum.Event
                }
            }
        ],
        totalResults: 3,
        maxDate: 0,
        minDate: 0,
        numPerPage: 100,
        pageNum: 0,
        type: undefined
    };
}
