import {useState} from "react";
import {OpLogListRequest} from "../../../../../api/oplogs";
import {getOpLogsPaged} from "../../../../../store/oplogList/actions/OpLogListActions";
import {useDispatch} from "react-redux";

export function useSharedOpLogTable() {
    const dispatch = useDispatch();
    const [request, setRequest] = useState<OpLogListRequest>();

    function onRequestChanged(entity: OpLogListRequest) {
        if (JSON.stringify(entity) === JSON.stringify(request)) return;
        setRequest(entity);
        refreshData(entity);
    }

    function refreshData(entity: OpLogListRequest) {
        dispatch(getOpLogsPaged(entity));
    }

    return {
        onRequestChanged,
        request,
        refreshData
    };
}
