import React from "react";
import {OpLog, OpLogSupplemental} from "../../../api/oplogs";
import FormRow from "../../Form/FormRow";
import {AllowedAdditionalFields, SapType} from "../../../store/oplog/actions/OpLogActionTypes";
import PulseEnumDropdown from "../../Dropdown/PulseEnumDropdown";
import {DebounceInput} from "react-debounce-input";

function StrategicLogFields({log, updateLog}: Props) {
    const {sapType, strategicIntention} = AllowedAdditionalFields;
    return (
        <React.Fragment>
            <FormRow rowName={"SAP Type"} columnDetailClassName={"pl-0 pr-0"}>
                <PulseEnumDropdown
                    searchable={false}
                    enumOptions={SapType}
                    disabled={false}
                    placeholder={"Select SAP Type..."}
                    value={
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        log.additionalFields[AllowedAdditionalFields.sapType]
                    }
                    clearable={false}
                    onChange={(option) => {
                        const additionalFields = {
                            ...log.additionalFields,
                            [sapType]: option?.value
                        };

                        updateLog({
                            ...log,
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            additionalFields
                        });
                    }}
                />
            </FormRow>
            <FormRow rowName={"Strategic Intention"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    debounceTimeout={300}
                    className={"input-fields-filters"}
                    type={"text"}
                    onChange={(event) => {
                        const {value} = event.target;
                        const additionalFields = {
                            ...log.additionalFields,
                            [strategicIntention]: value
                        };
                        updateLog({
                            ...log,
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            additionalFields
                        });
                    }}
                    disabled={false}
                    value={
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        log.additionalFields[strategicIntention]
                    }
                    placeholder="Enter Strategic Intention here..."
                />
            </FormRow>
        </React.Fragment>
    );
}

export default StrategicLogFields;

interface Props {
    log: OpLog | OpLogSupplemental;
    updateLog: (log: OpLog | OpLogSupplemental) => void;
}
