import {useEffect, useState} from "react";
import {StoreServiceData} from "store-fetch-wrappers";
import {OpLogSupplemental} from "../../../../../api/oplogs";
import {showErrorToast} from "../../../../../utils/toastUtils";
import OpLogsApiModel from "../../../../../store/apiModel/OpLogsApiModel";

export function useSupplementalLogListContainer(id: number) {
    const [state, setState] = useState<StoreServiceData<OpLogSupplemental[]>>({
        loading: false,
        error: null,
        data: []
    });

    const [supplementalLogFormOpened, setSupplementalLogFormOpened] = useState<boolean>(false);

    useEffect(() => {
        fetchData().then();
    }, []);

    async function fetchData(): Promise<boolean | undefined> {
        if (state.loading) return;
        try {
            setState({
                ...state,
                loading: true
            });
            const response = await OpLogsApiModel.getOpLogsApi().getLogSupplementals(id);
            if (response.status === 200) {
                setState({
                    ...state,
                    loading: false,
                    data: sortLogsByDateDesc(response.data)
                });
                return true;
            }
            setState({
                ...state,
                loading: false
            });
        } catch (e) {
            showErrorToast("Could not fetch data");
            setState({
                ...state,
                loading: false
            });
            return false;
        }
    }

    function sortLogsByDateDesc(logs: OpLogSupplemental[]): OpLogSupplemental[] {
        return logs.sort((a, b) => b.dateSaved - a.dateSaved);
    }

    return {
        fetchData,
        state,
        supplementalLogFormOpened,
        setSupplementalLogFormOpened
    };
}
