import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {OpLogListResponse} from "../../../api/oplogs";
import {OPLOG_LIST_STORE_STATE} from "../actions/OpLogListActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<OpLogListResponse>(getBlankOpLogsListResponse());

const opLogListReducer = (
    state: StoreServiceData<OpLogListResponse> = defaultState,
    action: ServiceActionTypes<OpLogListResponse>
) => createReducer(state, action, OPLOG_LIST_STORE_STATE, () => showErrorToast(action.error));

export default opLogListReducer;

export function getBlankOpLogsListResponse(): OpLogListResponse {
    return {
        logs: [],
        maxDate: 0,
        minDate: 0,
        numPerPage: 0,
        pageNum: 0,
        totalResults: 0
    };
}
