import React from "react";
import {OpLogSupplemental} from "../../../../api/oplogs";
import {toArray} from "../../../../utils/sortingUtils";
import MobileRowItem from "../../../Pages/OperationalLogsList/Components/Mobile/MobileRowItem";
import NoItemsMessage from "../../../Table/NoItemsMessage";

function SupplementalLogListMobileItemContainer(props: OpLogSupplemental[]) {
    return (
        <React.Fragment>
            <div className="pl-3 pr-3 pb-4">
                {toArray(props).length > 0 ? (
                    <React.Fragment>
                        {toArray(props).map((item: OpLogSupplemental, index) => {
                            return (
                                <MobileRowItem
                                    opLog={item}
                                    key={index}
                                    className="supplemental-log-item"
                                />
                            );
                        })}
                    </React.Fragment>
                ) : (
                    <NoItemsMessage message={"There are no Supplemental logs"} />
                )}
            </div>
        </React.Fragment>
    );
}

export default SupplementalLogListMobileItemContainer;
