import React from "react";
import {DynamicKeyValue} from "../../../../../store/oplog/actions/OpLogActionTypes";

function SupplementalLogsTableHeader({headers}: Props) {
    return (
        <React.Fragment>
            <tr>
                {Object.keys(headers).map((item: string) => {
                    return (
                        <th
                            className="supplemental-table-header-item"
                            key={item}
                            colSpan={headers[item] === "Remarks" ? 4 : 1}
                        >
                            {
                                // @ts-ignore
                                headers[item]
                            }
                        </th>
                    );
                })}
            </tr>
        </React.Fragment>
    );
}

export default SupplementalLogsTableHeader;

interface Props {
    headers: DynamicKeyValue;
}
