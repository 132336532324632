import React from "react";
import {OpLog, OpLogSupplemental} from "../../../api/oplogs";
import FormRow from "../../Form/FormRow";
import CalendarListDropdown from "../../Dropdown/CalendarListDropdown";
import {AllowedAdditionalFields} from "../../../store/oplog/actions/OpLogActionTypes";
import {DebounceInput} from "react-debounce-input";
import CharacterLimitWarning from "../../Form/CharacterLimitWarning";

function TacticalLogFields({log, updateLog}: Props) {
    const {departmentId, departmentName, callSign, calendarType} = AllowedAdditionalFields;
    return (
        <React.Fragment>
            <FormRow rowName={"Department"} columnDetailClassName={"pl-0 pr-0"}>
                <CalendarListDropdown
                    calendarId={
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        +log.additionalFields[departmentId]
                    }
                    onChange={(calendar) => {
                        const additionalFields = {
                            ...log.additionalFields,
                            [departmentId]: calendar?.id,
                            [departmentName]: calendar?.name,
                            [calendarType]: calendar?.calendarType
                        };

                        updateLog({
                            ...log,
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            additionalFields
                        });
                    }}
                    clearable={true}
                />
            </FormRow>
            <FormRow rowName={"Call Sign"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    debounceTimeout={300}
                    className={"input-fields-filters"}
                    type={"text"}
                    onChange={(event) => {
                        const {value} = event.target;
                        const additionalFields = {
                            ...log.additionalFields,
                            [callSign]: value
                        };
                        updateLog({
                            ...log,
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            additionalFields
                        });
                    }}
                    disabled={false}
                    value={
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        log.additionalFields[callSign]
                    }
                    placeholder="Enter Call Sign here..."
                />
                <CharacterLimitWarning
                    characterLimit={25}
                    textValue={
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        log.additionalFields[callSign] || ""
                    }
                />
            </FormRow>
        </React.Fragment>
    );
}

export default TacticalLogFields;

interface Props {
    log: OpLog | OpLogSupplemental;
    updateLog: (log: OpLog | OpLogSupplemental) => void;
}
