import React, {useEffect} from "react";
import {RequestFilterProps} from "../../../../Filters/helpers/filterHelpers";
import {OpLogListRequest} from "../../../../../api/oplogs";
import {useOpLogsTableFilters} from "../../Hooks/Shared/useOpLogsTableFilters";
import {LogTypeFilters} from "../../../../../store/oplog/actions/OpLogActionTypes";

function OpLogsTableFilters({onRequestChanged, logType}: Props) {
    const {request, renderFilters} = useOpLogsTableFilters(logType);

    useEffect(() => {
        if (!request) return;
        onRequestChanged(request);
    }, [request]);

    return <React.Fragment>{renderFilters()}</React.Fragment>;
}

export default OpLogsTableFilters;

interface Props extends RequestFilterProps<OpLogListRequest> {
    logType: LogTypeFilters;
}
