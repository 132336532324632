import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {OpLogListResponse} from "../../../api/oplogs";

export const OPLOG_LIST_STORE_STATE = createStoreState("oplog_list");

interface OpLogListLoading extends StoreServiceData<OpLogListResponse> {
    type: typeof OPLOG_LIST_STORE_STATE.LOADING;
}
interface OpLogListError extends StoreServiceData<OpLogListResponse> {
    type: typeof OPLOG_LIST_STORE_STATE.ERROR;
}
interface OpLogListSuccess extends StoreServiceData<OpLogListResponse> {
    type: typeof OPLOG_LIST_STORE_STATE.SUCCESS;
}

export type OpLogListDispatchTypes = OpLogListLoading | OpLogListError | OpLogListSuccess;
