import {
    AllowedAdditionalFields,
    DynamicKeyValue
} from "../../../store/oplog/actions/OpLogActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";
import {OpLog, OpLogSupplemental} from "../../../api/oplogs";

export function useBaseFormValidation() {
    const {sapType, departmentId, departmentName} = AllowedAdditionalFields;
    function isAdditionalFieldsValid(keys: string[], additionalFields?: DynamicKeyValue): boolean {
        if (!additionalFields) return false;
        if (Object.keys(additionalFields).length === 0) return false;

        for (const key of keys) {
            if (!additionalFields[key]) {
                showErrorToast(`${key} must be specified.`);
                return false;
            }

            if (additionalFields[key].length === 0) {
                showErrorToast(`${key} must be specified.`);
                return false;
            }

            if (!isNaN(+additionalFields[key]) && +additionalFields[key] === 0) {
                showErrorToast(`${key} must be specified.`);
                return false;
            }
        }

        return true;
    }

    function validateStrategicLog(log: OpLog | OpLogSupplemental): boolean {
        const {additionalFields} = log;

        if (!isAdditionalFieldsValid([sapType], additionalFields)) return false;
        return true;
    }

    function validateTacticalLog(log: OpLog | OpLogSupplemental): boolean {
        const {additionalFields} = log;

        if (!isAdditionalFieldsValid([departmentId, departmentName], additionalFields))
            return false;

        return true;
    }

    return {
        isAdditionalFieldsValid,
        validateStrategicLog,
        validateTacticalLog
    };
}
