import {OpLog} from "../../../api/oplogs";
import {formatUnixToCustomFormat} from "../../../utils/momentUtils";
import {
    AllowedAdditionalFields,
    DynamicKeyValue,
    LogTypeFilters
} from "../../../store/oplog/actions/OpLogActionTypes";
import {getUiFriendlyText} from "../../../utils/textUtils";

export function useOpLogTableRow() {
    const {callSign, sapType, departmentName, strategicIntention} = AllowedAdditionalFields;
    function getOpLogTableRow(
        logType: LogTypeFilters,
        log: OpLog
    ): TacticalLogTableRow | StrategicLogTableRow | undefined {
        switch (logType) {
            case LogTypeFilters.Strategic:
                return getStrategicLogTableRow(log);
            case LogTypeFilters.Tactical:
                return getTacticalLogTableRow(log);
        }
    }

    function getTacticalLogTableRow({
        id,
        remarks,
        dateSaved,
        additionalFields,
        author
    }: OpLog): TacticalLogTableRow {
        const department = getValueFromAdditionalFields(departmentName, additionalFields);
        const callsign = getValueFromAdditionalFields(callSign, additionalFields);
        return {
            id,
            createdBy: author.staffName,
            dateCreated: formatUnixToCustomFormat(dateSaved, "DD/MM/YY HH:mm"),
            remarks,
            department,
            callSign: callsign
        };
    }

    function getStrategicLogTableRow({
        id,
        remarks,
        dateSaved,
        additionalFields,
        author
    }: OpLog): StrategicLogTableRow {
        const saptype = getValueFromAdditionalFields(sapType, additionalFields);
        const stratIntention = getValueFromAdditionalFields(strategicIntention, additionalFields);
        return {
            id,
            createdBy: author.staffName,
            dateCreated: formatUnixToCustomFormat(dateSaved, "DD/MM/YY HH:mm"),
            remarks,
            strategicIntention: stratIntention,
            sapType: getUiFriendlyText(saptype)
        };
    }

    function getValueFromAdditionalFields(key: string, additionalFields?: DynamicKeyValue): string {
        if (!additionalFields) return "N/A";

        return additionalFields[key] || "N/A";
    }

    return {
        getOpLogTableRow,
        getTacticalLogTableRow,
        getStrategicLogTableRow
    };
}

export interface TacticalLogTableRow {
    id: number;
    createdBy: string;
    dateCreated: string;
    remarks: string;
    department: string;
    callSign: string;
}

export interface StrategicLogTableRow {
    id: number;
    createdBy: string;
    dateCreated: string;
    remarks: string;
    strategicIntention: string;
    sapType: string;
}
