import React from "react";
import FormHeader from "../../../../Form/FormHeader";
import {LogTypeFilters} from "../../../../../store/oplog/actions/OpLogActionTypes";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../Button/MCButton";
import {getUiFriendlyText} from "../../../../../utils/textUtils";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../../Navigation/routeNames";

function LogSelection() {
    const history = useHistory();
    return (
        <React.Fragment>
            <FormHeader headerName={"Select Log"} />
            <div className="row ml-0 mr-0 mt-4">
                {Object.keys(LogTypeFilters).map((value: string, index: number) => {
                    return (
                        <React.Fragment key={index}>
                            <div className="col-sm-6 mb-3 oplog-log-option">
                                <MCButton
                                    size={ButtonSize.ExtraLarge}
                                    innerValue={getUiFriendlyText(value)}
                                    onClick={() => {
                                        // we append the enum to lower case to the end of the URL without having to do a switch
                                        // value == Tactical: pathname = "logs/tactical"
                                        // value == Strategic: pathname = "logs/strategic"
                                        const pathname = `${
                                            routeNames.logListParent.path
                                        }/${value.toLowerCase()}`;

                                        history.push(pathname);
                                    }}
                                    colour={ButtonColourOptions.DarkBlue}
                                    className={"w-100"}
                                />
                            </div>
                        </React.Fragment>
                    );
                })}
            </div>
        </React.Fragment>
    );
}

export default LogSelection;
