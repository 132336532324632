import {useEffect, useState} from "react";
import {OpLogSupplemental} from "../../../../../api/oplogs";
import {formatUnixToCustomFormat} from "../../../../../utils/momentUtils";
import {toArray} from "../../../../../utils/sortingUtils";

export function useSupplementalLogsTable(props: OpLogSupplemental[]) {
    const [items, setItems] = useState<TableRow[]>([]);

    useEffect(() => {
        setItems(getTableRows(props));
    }, [props]);

    function getTableRows(entity: OpLogSupplemental[]): TableRow[] {
        return toArray(entity).map(({author, remarks, dateSaved}) => {
            return {
                createdBy: author.staffName,
                dateCreated: formatUnixToCustomFormat(dateSaved, "DD/MM/YY HH:mm"),
                remarks
            };
        });
    }

    function getHeaders() {
        return {
            createdBy: "Created By",
            dateCreated: "Date Created",
            remarks: "Remarks"
        };
    }

    return {
        items,
        headers: getHeaders()
    };
}

interface TableRow {
    createdBy: string;
    dateCreated: string;
    remarks: string;
}
