import React from "react";
import {DynamicKeyValue} from "../../../store/oplog/actions/OpLogActionTypes";
import {ExpandTableRow} from "../Store/Actions/ExpandingTableActionTypes";

export interface TableProps<T extends ExpandingTableData> {
    items: T[];
    headers: TableHeaders<T>;
    customRenderers?: CustomRenderers<T>;
    noItemsSection: React.ReactNode;
    paginate?: boolean;
    resultsPerPage?: number;
    expandedRow: ExpandedRow<T>;
    viewActionText: string;

    initialExpandedRows?: ExpandTableRow[];
}

export type PrimitiveType = string | symbol | number | boolean;

export function isPrimitive(value: any): value is PrimitiveType {
    return (
        typeof value === "string" ||
        typeof value === "number" ||
        typeof value === "boolean" ||
        typeof value === "symbol"
    );
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type TableHeaders<T extends ExpandingTableData> = Record<keyof T, string>;

export function objectValues<T extends ExpandingTableData>(obj: T) {
    return Object.keys(obj).map((objKey) => obj[objKey as keyof T]);
}
// eslint-disable-next-line @typescript-eslint/ban-types
export function objectKeys<T extends ExpandingTableData>(obj: T) {
    return Object.keys(obj).map((objKey) => objKey as keyof T);
}

export type CustomRenderers<T extends ExpandingTableData> = Partial<
    Record<keyof T, (it: T) => React.ReactNode>
>;

export interface ExpandedRow<T> {
    viewMoreRenderedItem: (item: T) => React.ReactNode;
}

export interface ExpandingTableData extends DynamicKeyValue {
    id: string;
}
