import React from "react";
import {OpLog, OpLogSupplemental} from "../../../api/oplogs";
import {getLogTypeFromString} from "../../../utils/enumUtils";
import {LogType} from "../../../store/oplog/actions/OpLogActionTypes";
import TacticalLogFields from "../Components/TacticalLogFields";
import StrategicLogFields from "../Components/StrategicLogFields";

export function useRenderLogTypeForm() {
    function renderForm(
        opLog: OpLog | OpLogSupplemental,
        updateOperationLogs: (updated: OpLog | OpLogSupplemental) => void,
        type?: string
    ): React.ReactNode {
        const logType = getLogTypeFromString(type);
        switch (logType) {
            case LogType.Tactical:
                return <TacticalLogFields log={opLog} updateLog={updateOperationLogs} />;
            case LogType.Strategic:
                return <StrategicLogFields log={opLog} updateLog={updateOperationLogs} />;

            default:
                return <div />;
        }
    }

    return {
        renderForm
    };
}
