import React from "react";
import {ExpandingTableData, objectKeys, TableHeaders} from "../Helpers/TableHelpers";
import ExpandingTableHeading from "./ExpandingTableHeading";

function ExpandingTableHeader<T extends ExpandingTableData>({headers}: Props<T>) {
    return (
        <React.Fragment>
            <thead>
                <tr className="pulse-table-header">
                    {
                        // @ts-ignore
                        objectKeys(headers).map((headerKey, index) => (
                            <ExpandingTableHeading
                                key={index}
                                headerKey={headerKey.toString()}
                                headerValue={headers[headerKey]}
                            />
                        ))
                    }
                </tr>
            </thead>
        </React.Fragment>
    );
}

export default ExpandingTableHeader;

interface Props<T extends ExpandingTableData> {
    headers: TableHeaders<T>;
}
