import React from "react";
import {ExpandingTableData, TableProps} from "./Helpers/TableHelpers";
import PulseExpandingTable from "./PulseExpandingTable";
import ReactPaginate from "react-paginate";
import Icon, {IconType} from "../../components/Icon/Icon";
import {usePagination} from "../../components/Hooks/usePagination";

function PulseExpandingTableWithServerPagination<T extends ExpandingTableData>({
    resultsPerPage,
    totalResults,
    onPageChanged,
    initialPageNumber,
    ...rest
}: PulseExpandingTableWithServerPaginationProps<T>) {
    const {pageCount, changePage, pageNumber} = usePagination({
        resultsPerPage,
        totalResults,
        onPageChanged,
        initialPageNumber
    });

    return (
        <React.Fragment>
            <PulseExpandingTable {...rest} />
            {rest.items.length > 0 && (
                <div className="pagination-wrapper pt-5">
                    <ReactPaginate
                        pageCount={pageCount}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={1}
                        containerClassName={"pagination"}
                        activeClassName={"active-page"}
                        onPageChange={changePage}
                        forcePage={pageNumber}
                        previousLabel={
                            <Icon
                                rootElement={"Span"}
                                rootElementClassName="d-grid"
                                className="nav-links next-previous-icon"
                                icon={IconType.Previous}
                                size={"Small"}
                            />
                        }
                        nextLabel={
                            <Icon
                                rootElement={"Span"}
                                rootElementClassName="d-grid"
                                className="nav-links next-previous-icon"
                                icon={IconType.Next}
                                size={"Small"}
                            />
                        }
                        pageClassName={"other-pages"}
                        breakClassName={"other-pages"}
                        nextClassName={"next-page"}
                        previousClassName={"previous-page"}
                        pageLinkClassName={"pagination-link"}
                        activeLinkClassName={"active-pagination-link"}
                    />
                </div>
            )}
        </React.Fragment>
    );
}
export default PulseExpandingTableWithServerPagination;

interface PulseExpandingTableWithServerPaginationProps<T extends ExpandingTableData>
    extends TableProps<T> {
    resultsPerPage: number;
    totalResults: number;
    onPageChanged: (newPageNum: number) => void;
    initialPageNumber?: number;
}
