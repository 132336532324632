import React from "react";
import {useEditSupplementalLog} from "../../Hooks/useEditSupplementalLog";
import FormRow from "../../../../Form/FormRow";
import {DebounceInput} from "react-debounce-input";
import CharacterLimitWarning from "../../../../Form/CharacterLimitWarning";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../Button/MCButton";
import ButtonWithConfirmation from "../../../../Button/ButtonWithConfirmation";
import FormActionContainer from "../../../../Form/FormActionContainer";
import {EditSupplementalLogFormProps} from "../Desktop/EditSupplementalLogDesktopForm";
import HeaderWithActions from "../../../List/Desktop/Components/HeaderWithActions";

function EditSupplementalLogMobileForm({parentId, onSave, onCancel}: EditSupplementalLogFormProps) {
    const {updateForm, form, save, clearForm} = useEditSupplementalLog({parentId});
    return (
        <React.Fragment>
            <div className="mobile-view-expanded-row-wrapper pb-1 pt-2">
                <HeaderWithActions headerName={"New Supplemental Log Entry"} />
                <FormRow rowName={"Remarks"} columnDetailClassName={"pl-0 pr-0"}>
                    <DebounceInput
                        debounceTimeout={300}
                        className={"input-fields text-area-inputs"}
                        element={"textarea"}
                        onChange={(event) => {
                            const {value} = event.target;
                            updateForm({
                                ...form,
                                remarks: value
                            });
                        }}
                        disabled={false}
                        value={form.remarks}
                        placeholder="Enter remarks here..."
                    />
                    <CharacterLimitWarning characterLimit={10000} textValue={form.remarks} />
                </FormRow>
                <FormActionContainer wrapperClassName="m-1">
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Save"}
                        onClick={async () => {
                            await save();

                            onSave();
                        }}
                        roundedCorner
                        colour={ButtonColourOptions.Yellow}
                    />
                    <ButtonWithConfirmation
                        buttonText={"Clear"}
                        action={async () => {
                            await clearForm();
                            onCancel();
                        }}
                        actionButtonColour={ButtonColourOptions.DarkBlue}
                        message={"Are you sure you want to clear this supplemental log entry?"}
                    />
                </FormActionContainer>
            </div>
        </React.Fragment>
    );
}

export default EditSupplementalLogMobileForm;
