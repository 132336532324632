import React from "react";
import {PulseCalendarDropdown, useCalendarListDropdown} from "./Hooks/useCalendarListDropdown";
import {Loading} from "store-fetch-wrappers";
import Select from "react-select";

function CalendarListDropdown(props: PulseCalendarDropdown) {
    const {selectedOption, handleCalendarChange, options, calendarListStore} =
        useCalendarListDropdown(props);
    return (
        <React.Fragment>
            {calendarListStore.loading ? (
                <Loading type={"three-ring"} showLoadingText={false} />
            ) : (
                <Select
                    className="search-bar"
                    classNamePrefix="select-options"
                    options={options}
                    onChange={handleCalendarChange}
                    isSearchable={props.searchable}
                    value={selectedOption}
                    noOptionsMessage={() => props.noItemsMessage}
                    placeholder={props.placeholder}
                    isDisabled={props.disabled}
                    isClearable={props.clearable}
                    menuPortalTarget={document.body}
                />
            )}
        </React.Fragment>
    );
}

export default CalendarListDropdown;
