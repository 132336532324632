import React, {useEffect, useState} from "react";
import {ExpandedRow} from "../Helpers/TableHelpers";
import {ExpandAction, ExpandRenderAction} from "../Components/ExpandingTableRowCell";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import {updateOpenedRow} from "../Store/Actions/ExpandingTableActions";
import {ExpandTableRow} from "../Store/Actions/ExpandingTableActionTypes";

export function useExpandingTableRow<T extends {id: number | string}>({
    expandedRow,
    initialExpandedRows,
    row,
    rowId
}: Props<T>) {
    const [currentOpenRow, setCurrentOpenRow] = useState<ExpandRenderAction<T> | null>(null);
    const [rowOpenType, setRowOpenType] = useState<ExpandAction>();
    const [currentChild, setCurrentChild] = useState<React.ReactNode>();
    const [expanded, setExpanded] = useState<boolean>(false);

    const dispatch = useDispatch();
    const expandedRowStore = useSelector((state: RootStore) => state.expandingTable);

    const [renderExpandableRow, setRenderExpandableRow] = useState<
        (id: string) => React.ReactNode | undefined
    >(() => expandedRowMarkup(false, undefined));

    useEffect(() => {
        const rows = expandedRowStore.data?.rows || [];
        if (rows.length === 0) return;
        // @ts-ignore
        const selectedRow = rows.find((item) => item.id === +currentOpenRow?.item?.id);

        if (!selectedRow) resetRow();
    }, [expandedRowStore, currentOpenRow]);

    useEffect(() => {
        if (!initialExpandedRows) return;
        const first = firstOrNull(initialExpandedRows);
        if (!first) return;

        if (first.id !== +row.id) return;
        if (!first.expandAction) return;

        const rowItem: ExpandRenderAction<T> = {
            item: row,
            action: first.expandAction
        };

        updateExpandedRow(rowItem, true);
    }, [initialExpandedRows, row]);

    function firstOrNull<R>(array: R[]): R | undefined {
        return array[0];
    }

    function onExpandedItemSelected({item, action}: ExpandRenderAction<T>) {
        //Section is being minimized here.
        if (currentOpenRow?.item?.id === item.id && action === currentOpenRow?.action) {
            resetRow();
            return;
        }
        updateExpandedRow({item, action});
    }

    function updateExpandedRow({item, action}: ExpandRenderAction<T>, scrollTo?: boolean) {
        setCurrentOpenRow({item, action});
        dispatch(updateOpenedRow({id: +item.id, expandAction: action}));

        const {viewMoreRenderedItem} = expandedRow;
        switch (action) {
            case ExpandAction.View:
                setRenderExpandableRow(() => expandedRowMarkup(true, viewMoreRenderedItem(item)));
                setRowOpenType(action);
                break;
        }
        setExpanded(true);

        if (scrollTo) {
            scrollToElement(rowId);
        }
    }

    function resetRow() {
        setCurrentOpenRow(null);
        setRenderExpandableRow(() => expandedRowMarkup(false, currentChild));
        setRowOpenType(undefined);
        setExpanded(false);
    }

    function expandedRowMarkup(open: boolean, children: React.ReactNode): React.ReactNode {
        setCurrentChild(children);
        return children;
    }

    function scrollToElement(id: string) {
        const item = document.getElementById(id);
        if (!item) return;

        const {y} = item.getBoundingClientRect();

        window.scrollTo({
            top: y,
            left: 0,
            behavior: "smooth"
        });
    }

    function getPrefixText(action: ExpandAction | undefined, isViewText: boolean): string {
        if (!action) return "View";
        switch (action) {
            case ExpandAction.View:
                return isViewText ? "Hide" : "View";
            default:
                return "";
        }
    }

    return {
        renderExpandableRow,
        onExpandedItemSelected,
        rowOpenType,
        getPrefixText,
        expanded
    };
}

interface Props<T> {
    expandedRow: ExpandedRow<T>;
    initialExpandedRows?: ExpandTableRow[];
    row: T;
    rowId: string;
}
