import React from "react";

function SupplementalLogsTableRow({dateCreated, remarks, author}: Props) {
    return (
        <React.Fragment>
            <tr className="supplemental-table-row">
                <td className="supplemental-table-row-item" colSpan={1}>
                    {author}
                </td>
                <td className="supplemental-table-row-item" colSpan={1}>
                    {dateCreated}
                </td>
                <td className="supplemental-table-row-item" colSpan={4}>
                    {remarks}
                </td>
            </tr>
        </React.Fragment>
    );
}

export default SupplementalLogsTableRow;

interface Props {
    dateCreated: string;
    author: string;
    remarks: string;
}
