/* tslint:disable */
/* eslint-disable */
/**
 * Medicare OpLogs
 * API for Medicare Operation Logs
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * An error has occurred, and this object contains the detailed information
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    'message': string;
}
/**
 * Top-level log entry. Can have sub-entries to add supplemental information. Once a log has been inserted it is immutable and adjustments are only possible via supplemental entries. Some fields will be overridden by the server.
 * @export
 * @interface OpLog
 */
export interface OpLog {
    /**
     * Unique ID for this log entry. If 0 the entry will be inserted and a new ID assigned.
     * @type {number}
     * @memberof OpLog
     */
    'id': number;
    /**
     * Type of log
     * @type {string}
     * @memberof OpLog
     */
    'type'?: string;
    /**
     * 
     * @type {StaffLink}
     * @memberof OpLog
     */
    'author': StaffLink;
    /**
     * Epoch date that the entry was saved to the database
     * @type {number}
     * @memberof OpLog
     */
    'dateSaved': number;
    /**
     * Primary log entry content itself
     * @type {string}
     * @memberof OpLog
     */
    'remarks': string;
    /**
     * Free-form key/value pairs that represent additional properties that can be added. Explicitly a map to aid in indexing / filtering. The server will often return an empty object to help prevent null / undefined references.
     * @type {{ [key: string]: string; }}
     * @memberof OpLog
     */
    'additionalFields'?: { [key: string]: string; };
}
/**
 * Operation log and supplemental logs in one object
 * @export
 * @interface OpLogComplete
 */
export interface OpLogComplete {
    /**
     * 
     * @type {OpLog}
     * @memberof OpLogComplete
     */
    'log': OpLog;
    /**
     * Any supplemental logs for the primary log, empty array if none
     * @type {Array<OpLogSupplemental>}
     * @memberof OpLogComplete
     */
    'supplemental': Array<OpLogSupplemental>;
}
/**
 * Request a list of operation logs that meet specific criteria
 * @export
 * @interface OpLogListRequest
 */
export interface OpLogListRequest {
    /**
     * Page number to fetch, starting from 0
     * @type {number}
     * @memberof OpLogListRequest
     */
    'pageNum'?: number;
    /**
     * 
     * @type {number}
     * @memberof OpLogListRequest
     */
    'numPerPage'?: number;
    /**
     * Minimum save epoch date for the log, inclusive
     * @type {number}
     * @memberof OpLogListRequest
     */
    'minDate': number;
    /**
     * Maximum save epoch date for the log, exclusive
     * @type {number}
     * @memberof OpLogListRequest
     */
    'maxDate': number;
    /**
     * Type of log
     * @type {string}
     * @memberof OpLogListRequest
     */
    'type'?: string;
    /**
     * Username of the author for the log
     * @type {string}
     * @memberof OpLogListRequest
     */
    'authorUsername'?: string;
    /**
     * Free-form key/value pairs that represent additional properties that should be filtered. Must be an exact match (case sensitive)
     * @type {{ [key: string]: string; }}
     * @memberof OpLogListRequest
     */
    'additionalFields'?: { [key: string]: string; };
}
/**
 * List of operation logs that meet the request criteria
 * @export
 * @interface OpLogListResponse
 */
export interface OpLogListResponse {
    /**
     * Matching operation logs for the page, empty if none
     * @type {Array<OpLog>}
     * @memberof OpLogListResponse
     */
    'logs': Array<OpLog>;
    /**
     * Page number to fetch, starting from 0
     * @type {number}
     * @memberof OpLogListResponse
     */
    'pageNum': number;
    /**
     * 
     * @type {number}
     * @memberof OpLogListResponse
     */
    'numPerPage': number;
    /**
     * Total amount of logs in the database. Used to calucalate page numbers in the UI
     * @type {number}
     * @memberof OpLogListResponse
     */
    'totalResults': number;
    /**
     * Minimum save epoch date for the log, inclusive
     * @type {number}
     * @memberof OpLogListResponse
     */
    'minDate': number;
    /**
     * Maximum save epoch date for the log, exclusive
     * @type {number}
     * @memberof OpLogListResponse
     */
    'maxDate': number;
    /**
     * Type of log
     * @type {string}
     * @memberof OpLogListResponse
     */
    'type'?: string;
    /**
     * Username of the author for the log
     * @type {string}
     * @memberof OpLogListResponse
     */
    'authorUsername'?: string;
    /**
     * Free-form key/value pairs that represent additional properties that should be filtered. Must be an exact match (case sensitive)
     * @type {{ [key: string]: string; }}
     * @memberof OpLogListResponse
     */
    'additionalFields'?: { [key: string]: string; };
}
/**
 * Additional supplemental entry added to an operation log
 * @export
 * @interface OpLogSupplemental
 */
export interface OpLogSupplemental {
    /**
     * Unique ID for this supplemental entry. If 0 the entry will be inserted and a new ID assigned.
     * @type {number}
     * @memberof OpLogSupplemental
     */
    'id': number;
    /**
     * Unique ID for the primary log
     * @type {number}
     * @memberof OpLogSupplemental
     */
    'parentId'?: number;
    /**
     * 
     * @type {StaffLink}
     * @memberof OpLogSupplemental
     */
    'author': StaffLink;
    /**
     * Epoch date that the entry was saved to the database, populated by the server
     * @type {number}
     * @memberof OpLogSupplemental
     */
    'dateSaved': number;
    /**
     * Primary log entry content itself
     * @type {string}
     * @memberof OpLogSupplemental
     */
    'remarks': string;
    /**
     * Free-form key/value pairs that represent additional properties that can be added. Explicitly a map to aid in indexing / filtering. The server will often return an empty object to help prevent null / undefined references.
     * @type {{ [key: string]: string; }}
     * @memberof OpLogSupplemental
     */
    'additionalFields'?: { [key: string]: string; };
}
/**
 * Direct Reference to a staff member
 * @export
 * @interface StaffLink
 */
export interface StaffLink {
    /**
     * Unique reference to the staff member
     * @type {string}
     * @memberof StaffLink
     */
    'staffId': string;
    /**
     * Real name of the staff member at moment of report relation
     * @type {string}
     * @memberof StaffLink
     */
    'staffName': string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a single operation log
         * @param {number} id Unique ID of the log
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLog: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLog', 'id', id)
            const localVarPath = `/oplogs/byid/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all supplemental logs for the provided main log
         * @param {number} id Unique ID of the primary log
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogSupplementals: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLogSupplementals', 'id', id)
            const localVarPath = `/oplogs/byid/{id}/supplemental`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a page of logs that meet the provided criteria
         * @param {OpLogListRequest} [opLogListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLogs: async (opLogListRequest?: OpLogListRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/oplogs/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(opLogListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a new log. If the log already exists (ID provided) the request will be ignored and the original returned.
         * @param {OpLog} [opLog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveLog: async (opLog?: OpLog, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/oplogs/log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(opLog, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a new supplemental log. If ID is specified the operation will be ignored and the existing entry returned.
         * @param {OpLogSupplemental} [opLogSupplemental] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveLogSupplemental: async (opLogSupplemental?: OpLogSupplemental, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/oplogs/supplemental`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(opLogSupplemental, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a single operation log
         * @param {number} id Unique ID of the log
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLog(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpLogComplete>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLog(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all supplemental logs for the provided main log
         * @param {number} id Unique ID of the primary log
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogSupplementals(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpLogSupplemental>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLogSupplementals(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a page of logs that meet the provided criteria
         * @param {OpLogListRequest} [opLogListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listLogs(opLogListRequest?: OpLogListRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpLogListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listLogs(opLogListRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save a new log. If the log already exists (ID provided) the request will be ignored and the original returned.
         * @param {OpLog} [opLog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveLog(opLog?: OpLog, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveLog(opLog, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Save a new supplemental log. If ID is specified the operation will be ignored and the existing entry returned.
         * @param {OpLogSupplemental} [opLogSupplemental] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveLogSupplemental(opLogSupplemental?: OpLogSupplemental, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpLogSupplemental>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveLogSupplemental(opLogSupplemental, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a single operation log
         * @param {number} id Unique ID of the log
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLog(id: number, options?: any): AxiosPromise<OpLogComplete> {
            return localVarFp.getLog(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all supplemental logs for the provided main log
         * @param {number} id Unique ID of the primary log
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogSupplementals(id: number, options?: any): AxiosPromise<Array<OpLogSupplemental>> {
            return localVarFp.getLogSupplementals(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a page of logs that meet the provided criteria
         * @param {OpLogListRequest} [opLogListRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLogs(opLogListRequest?: OpLogListRequest, options?: any): AxiosPromise<OpLogListResponse> {
            return localVarFp.listLogs(opLogListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save a new log. If the log already exists (ID provided) the request will be ignored and the original returned.
         * @param {OpLog} [opLog] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveLog(opLog?: OpLog, options?: any): AxiosPromise<OpLog> {
            return localVarFp.saveLog(opLog, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Save a new supplemental log. If ID is specified the operation will be ignored and the existing entry returned.
         * @param {OpLogSupplemental} [opLogSupplemental] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveLogSupplemental(opLogSupplemental?: OpLogSupplemental, options?: any): AxiosPromise<OpLogSupplemental> {
            return localVarFp.saveLogSupplemental(opLogSupplemental, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Get a single operation log
     * @param {number} id Unique ID of the log
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getLog(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getLog(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all supplemental logs for the provided main log
     * @param {number} id Unique ID of the primary log
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getLogSupplementals(id: number, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getLogSupplementals(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a page of logs that meet the provided criteria
     * @param {OpLogListRequest} [opLogListRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listLogs(opLogListRequest?: OpLogListRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listLogs(opLogListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save a new log. If the log already exists (ID provided) the request will be ignored and the original returned.
     * @param {OpLog} [opLog] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public saveLog(opLog?: OpLog, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).saveLog(opLog, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Save a new supplemental log. If ID is specified the operation will be ignored and the existing entry returned.
     * @param {OpLogSupplemental} [opLogSupplemental] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public saveLogSupplemental(opLogSupplemental?: OpLogSupplemental, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).saveLogSupplemental(opLogSupplemental, options).then((request) => request(this.axios, this.basePath));
    }
}


