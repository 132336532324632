import React, {useState} from "react";
import DatePickerInputButton from "../Button/DatePickerInputButton";
import DatePicker from "react-datepicker";
import moment, {Moment} from "moment";

function PulseDatePicker({
    onChange,
    initialDate,
    inline,
    calendarStartDay,
    minDate,
    showMonthYearPicker,
    dateFormat
}: Props) {
    const [startDate, setStartDate] = useState<Moment | null>(
        initialDate ? moment.unix(initialDate) : moment()
    );
    return (
        <DatePicker
            selected={startDate?.toDate()}
            onChange={(value) => {
                setStartDate(moment(value) || null);
                onChange(value);
            }}
            dateFormat={dateFormat}
            showMonthYearPicker={showMonthYearPicker}
            customInput={<DatePickerInputButton />}
            calendarStartDay={calendarStartDay || 1}
            inline={inline}
            minDate={minDate}
            portalId="root-portal"
        />
    );
}

export default PulseDatePicker;

interface Props {
    onChange: (value: Date | null) => void;
    initialDate?: number;
    inline?: boolean;
    calendarStartDay?: number;
    minDate?: Date | undefined | null;
    showMonthYearPicker?: boolean;
    dateFormat?: string | string[] | undefined;
}
