import {Dispatch} from "redux";
import {
    DynamicKeyValue,
    LogType,
    LogTypeFilters,
    OPLOG_STORE_STATE,
    OpLogDispatchTypes,
    SapType
} from "./OpLogActionTypes";
import {OpLog, OpLogSupplemental} from "../../../api/oplogs";
import {getDataFromServiceWithRedux, postDataToServiceWithRedux} from "store-fetch-wrappers";
import OpLogsApiModel from "../../apiModel/OpLogsApiModel";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import store, {RootStore} from "../../Store";
import {UserData} from "../../../api/staff";
import {getStaffLinkFromUserData} from "../../../utils/userUtils";
import {getCalendarList} from "../../calendarList/actions/CalendarListActions";
import {AllowedAdditionalFields} from "./OpLogActionTypes";

export function nullifyOpLogStore() {
    return async function (dispatch: Dispatch<OpLogDispatchTypes>) {
        dispatch({
            type: OPLOG_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
}

export function createBlankOpLog(logType: LogType | LogTypeFilters) {
    return async function (dispatch: Dispatch<OpLogDispatchTypes>, state: () => RootStore) {
        const {data} = state().auth;
        if (!data) return;
        const log = createBlankLog(data, logType);

        dispatch({
            type: OPLOG_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: {
                log,
                supplemental: []
            }
        });
    };
}

export function updateOpLog(opLog: OpLog) {
    return async function (dispatch: Dispatch<OpLogDispatchTypes>, state: () => RootStore) {
        const supplemental = state().opLog.data?.supplemental || [];
        dispatch({
            type: OPLOG_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: {
                log: opLog,
                supplemental
            }
        });
    };
}

export function getOpLog(id: number) {
    return async function (dispatch: Dispatch<OpLogDispatchTypes>) {
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await store.dispatch(getCalendarList());
            return await getDataFromServiceWithRedux(
                OPLOG_STORE_STATE,
                dispatch,
                () => OpLogsApiModel.getOpLogsApi().getLog(id),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: OPLOG_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
}

export function saveOpLog(log: OpLog) {
    return async function (dispatch: Dispatch<OpLogDispatchTypes>) {
        try {
            return await postDataToServiceWithRedux(
                OPLOG_STORE_STATE,
                dispatch,
                () => OpLogsApiModel.getOpLogsApi().saveLog(log),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: OPLOG_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
}

export function saveSupplementalLog(supp: OpLogSupplemental) {
    return async function (dispatch: Dispatch<OpLogDispatchTypes>) {
        try {
            return await postDataToServiceWithRedux(
                OPLOG_STORE_STATE,
                dispatch,
                () => OpLogsApiModel.getOpLogsApi().saveLogSupplemental(supp),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: OPLOG_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
}

export function createBlankLog(user: UserData, logType: LogType | LogTypeFilters): OpLog {
    return {
        additionalFields: getUpdatedLogFromLogType(logType),
        author: getStaffLinkFromUserData(user),
        dateSaved: 0,
        id: 0,
        remarks: "",
        type: logType
    };
}

function getUpdatedLogFromLogType(type: LogType | LogTypeFilters): DynamicKeyValue {
    switch (type) {
        case LogType.Tactical:
        case LogTypeFilters.Tactical:
            return onTacticalLogSelected();
        case LogType.Strategic:
        case LogTypeFilters.Tactical:
            return onStrategicLogSelected();
        default:
            return {};
    }
}

function onTacticalLogSelected(): DynamicKeyValue {
    const {departmentName, departmentId, callSign, calendarType} = AllowedAdditionalFields;
    return {
        [departmentId]: "",
        [departmentName]: "",
        [calendarType]: "",
        [callSign]: ""
    };
}

function onStrategicLogSelected(): DynamicKeyValue {
    const {sapType, strategicIntention} = AllowedAdditionalFields;
    return {
        [sapType]: SapType.Strategy,
        [strategicIntention]: ""
    };
}
