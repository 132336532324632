import {useState} from "react";
import {OpLogSupplemental} from "../../../../api/oplogs";
import {useUserData} from "../../../Hooks/useUserData";
import {getStaffLinkFromUserData} from "../../../../utils/userUtils";
import {showErrorToast, showSuccessToast} from "../../../../utils/toastUtils";
import OpLogsApiModel from "../../../../store/apiModel/OpLogsApiModel";

export function useEditSupplementalLog({parentId}: Props) {
    const user = useUserData();
    const [form, setForm] = useState<OpLogSupplemental>(getBlankForm());

    function updateForm(entity: OpLogSupplemental) {
        setForm(entity);
    }

    async function save() {
        if (!form) return;
        const isValid = validateForm(form); // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        if (!isValid) return;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await saveSupplementalLog(form);
        if (!success) return;
        showSuccessToast(`Saved Supplemental Log`);

        await clearForm();
    }

    async function saveSupplementalLog(log: OpLogSupplemental): Promise<boolean | undefined> {
        try {
            const response = await OpLogsApiModel.getOpLogsApi().saveLogSupplemental(log);

            if (response.status === 200 && response.data) {
                return true;
            }
        } catch (e: any) {
            showErrorToast("Could not save supplemental log");
            return false;
        }
    }

    function validateForm(supp: OpLogSupplemental): boolean {
        const {remarks} = supp;

        if (parentId === undefined) {
            showErrorToast(
                "Could not attach supplemental form to log. Please contact system admin."
            );
            return false;
        }

        if (remarks.length > 10000) {
            showErrorToast("Remarks field must be less than or equal to 10000 characters.");
            return false;
        }

        return true;
    }

    async function clearForm() {
        setForm(getBlankForm());
    }

    function getBlankForm(): OpLogSupplemental {
        return {
            author: getStaffLinkFromUserData(user),
            dateSaved: 0,
            id: 0,
            remarks: "",
            parentId
        };
    }

    return {
        form,
        updateForm,
        save,
        clearForm
    };
}

interface Props {
    parentId: number;
}
