import React from "react";
import {IconType} from "../../../Icon/Icon";
import {useSupplementalLogListContainer} from "../Shared/Hooks/useSupplementalLogListContainer";
import {WithServiceState} from "store-fetch-wrappers";
import SupplementalLogListMobileItemContainer from "./SupplementalLogListMobileItemContainer";
import HeaderWithActions from "../Desktop/Components/HeaderWithActions";
import EditSupplementalLogMobileForm from "../../Form/Components/Mobile/EditSupplementalLogMobileForm";

const ServiceWrapper = WithServiceState(SupplementalLogListMobileItemContainer);

function SupplementalLogListMobileContainer({id}: Props) {
    const {fetchData, state, setSupplementalLogFormOpened, supplementalLogFormOpened} =
        useSupplementalLogListContainer(id);

    return (
        <React.Fragment>
            <div className="mobile-view-expanded-row-wrapper-lite">
                <HeaderWithActions
                    headerName={"Supplemental Logs"}
                    actions={[
                        {
                            icon: IconType.Refresh_Round,
                            onClick: () => fetchData(),
                            iconSize: "Large"
                        },
                        {
                            icon: IconType.Add_Basic,
                            onClick: () => {
                                let opened = supplementalLogFormOpened;
                                setSupplementalLogFormOpened((opened = !opened));
                            },
                            iconSize: "Large"
                        }
                    ]}
                />
                {supplementalLogFormOpened && (
                    <EditSupplementalLogMobileForm
                        parentId={id}
                        onSave={async () => {
                            setSupplementalLogFormOpened(false);
                            await fetchData();
                        }}
                        onCancel={() => setSupplementalLogFormOpened(false)}
                    />
                )}
                <ServiceWrapper
                    {...state}
                    showLoadingText={false}
                    loaderType={"overlay"}
                    loaderWheelType={"three-ring"}
                />
            </div>
        </React.Fragment>
    );
}

export default SupplementalLogListMobileContainer;

interface Props {
    id: number;
}
