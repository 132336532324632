import React from "react";
import {OpLogTableProps} from "../Desktop/OpLogsDesktopTable";
import {useOpLogsTableWrapper} from "../../Hooks/Shared/useOpLogsTableWrapper";

function OpLogsTableWrapper({logType, response}: OpLogTableProps) {
    const {renderView} = useOpLogsTableWrapper({logType, response});
    return <React.Fragment>{renderView()}</React.Fragment>;
}

export default OpLogsTableWrapper;
