import React from "react";
import {OpLog, OpLogSupplemental} from "../../../../../api/oplogs";
import {useMobileRowItem} from "../../Hooks/Mobile/useMobileRowItem";
import {
    ExpandAction,
    ExpandRenderAction
} from "../../../../../modules/Table/Components/ExpandingTableRowCell";

function MobileRowItem({opLog, onRowClicked, className}: Props) {
    const {
        getAuthorInitials,
        getBasicDetailsMarkup,
        getAdditionalDetailsForLog,
        isSupplementalLog
    } = useMobileRowItem(opLog);
    return (
        <React.Fragment>
            <div
                className={`row ${className}`}
                onClick={() => {
                    if (isSupplementalLog(opLog)) return;
                    if (!onRowClicked) return;
                    onRowClicked({item: opLog, action: ExpandAction.View});
                }}
            >
                <div className="mobile-view-section-fg-1">
                    <div className="mobile-view-initials-wrapper">
                        <div className="mobile-view-initials">{getAuthorInitials()}</div>
                    </div>
                </div>
                <div className="mobile-view-details-wrapper mobile-view-section-fg-8">
                    {getBasicDetailsMarkup()}
                    <div className={`mobile-view-extended-details`}>
                        {getAdditionalDetailsForLog()}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default MobileRowItem;

interface Props {
    opLog: OpLog | OpLogSupplemental;
    onRowClicked?: ({item, action}: ExpandRenderAction<OpLog>) => void;
    className: string;
}
