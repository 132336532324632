import {UserData} from "../api/staff";
import {StaffLink} from "../api/oplogs";

export const getFullName = ({firstName, lastName}: UserData) => `${firstName} ${lastName}`;

export function getStaffLinkFromUserData(user: UserData): StaffLink {
    return {
        staffId: user.username,
        staffName: getFullName(user)
    };
}
