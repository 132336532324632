import {combineReducers} from "redux";
import authReducer from "./auth/reducer/AuthReducer";
import navigationReducer from "./navigation/reducer/NavigationReducer";
import sidebarReducer from "./sidebar/reducer/sidebarReducer";
import mcConfigReducer from "./mcConfig/reducer/McConfigReducer";
import opLogReducer from "./oplog/reducer/OpLogsReducer";
import opLogListReducer from "./oplogList/reducer/OpLogsListReducer";
import calendarListReducer from "./calendarList/reducer/CalendarListReducer";
import expandingTableReducer from "../modules/Table/Store/Reducer/ExpandingTableReducer";

const RootReducer = combineReducers({
    navigation: navigationReducer,
    sidebar: sidebarReducer,
    auth: authReducer,
    mcConfig: mcConfigReducer,
    opLog: opLogReducer,
    opLogList: opLogListReducer,
    calendarList: calendarListReducer,
    expandingTable: expandingTableReducer
});

export default RootReducer;
